import React from "react"

import Form from "../../../components/form/form"
import FormNumericInput from "../../../components/form/form-numeric-input"
import LineChart, {
  makeChartConfiguration,
} from "../../../components/line-chart"
import Page from "../../../components/page"
import { OneColumnGrid, ThreeColumnsGrid } from "../../../components/containers"
import { SectionLarge } from "../../../components/elements"
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "../../../helpers/functions"

export default class InflationPage extends React.Component {
  state = {
    inputs: {
      capital: 10000,
      inflationRate: 0.02,
      numberOfYears: 30,
    },
    outputs: {
      capitalDataset: [],
      finalValue: 0,
    },
  }

  _compute = () => {
    const { inputs, outputs } = this.state
    let { capital, inflationRate, numberOfYears } = inputs
    const capitalDataset = [capital]
    while (numberOfYears > 0) {
      numberOfYears -= 1
      capital *= 1 - inflationRate
      capitalDataset.push(capital)
    }
    this.setState({
      ...this.state,
      outputs: {
        ...outputs,
        capitalDataset,
        finalValue: capital,
      },
    })
  }

  _handleUpdate = event => {
    event.preventDefault()
    const { name, value } = event.target
    let newState = { ...this.state }
    newState.inputs[name] = parseFloat(value) / (value.includes("%") ? 100 : 1)
    this.setState(newState)
    this._compute()
  }

  _handleSubmit = event => {
    event.preventDefault()
    this._compute()
  }

  componentDidMount() {
    this._compute()
  }

  render() {
    const { inputs, outputs } = this.state
    const { capital, inflationRate, numberOfYears } = inputs
    const { capitalDataset, finalValue } = outputs
    const chartData = {
      labels: capitalDataset.map((_, i) => `${i}`),
      datasets: [
        {
          ...makeChartConfiguration({
            label: "Potere d'acquisto del capitale",
          }),
          data: capitalDataset,
        },
      ],
    }
    const devaluationPercentage = 1 - finalValue / capital
    return (
      <Page title="Calcolatore Inflazione">
        <SectionLarge>
          <h1 className="heading">Calcola l'effetto dell'inflazione</h1>
          <h2 className="subheading">
            Vedi come cambia il potere d'acquisto di una somma di denaro nel
            tempo applicando un determinato tasso d'inflazione medio.
          </h2>
          <OneColumnGrid>
            <Form onSubmit={this._handleSubmit}>
              <ThreeColumnsGrid>
                <FormNumericInput
                  label="Capitale Iniziale"
                  format="currency"
                  name="capital"
                  value={capital}
                  onChange={this._handleUpdate}
                />
                <FormNumericInput
                  label="Tasso di inflazione"
                  format="percentage"
                  name="inflationRate"
                  onChange={this._handleUpdate}
                  value={inflationRate}
                />
                <FormNumericInput
                  label="Numero di anni"
                  name="numberOfYears"
                  onChange={this._handleUpdate}
                  value={numberOfYears}
                  maxValue={100}
                />
              </ThreeColumnsGrid>
            </Form>
            <p className="text-center">
              {formatCurrency(capital)} varranno{" "}
              <strong>{formatCurrency(finalValue)}</strong> dopo{" "}
              {formatNumber(numberOfYears)} anni, o{" "}
              <strong>{formatPercentage(devaluationPercentage)} in meno</strong>{" "}
              del potere d'acquisto originale.
            </p>
            <LineChart data={chartData} />
          </OneColumnGrid>
        </SectionLarge>
      </Page>
    )
  }
}
